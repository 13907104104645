import axios from 'axios';
import { Avatar, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

import Page from '../../components/Page';
import Services from '../../service/Services';
import { localdata } from '../../service/localdata';
import Iconify from '../../components/Iconify';

const { MaterialReactTable } = require("material-react-table");
const { useEffect, useMemo, useState } = require("react");


// ----------------------------------------------------------------------
const UserList = () => {

    const columns = useMemo(() => [
        {
            accessorKey: 'user', Cell: ({ cell, row }) => {
                return <div className='d-flex align-items-center'><Avatar className='me-2' src={row?.original?.user?.picture} /> {row?.original?.user?.name || '-'}</div>
            },
            header: 'Updated By'
        },
        { accessorKey: 'tableName', header: 'Table Name' },
        { accessorKey: 'action', header: 'Action', filterVariant: 'multi-select',
            filterSelectOptions: [
                {
                    value: 'UPDATE',
                    text: 'UPDATE'
                },
                {
                    value: 'DELETE',
                    text: 'DELETE'
                }
            ] },
        { accessorKey: 'timestamp', header: 'Updated At', Cell: ({ cell, row }) => {
            return <div className='d-flex align-items-center'>{moment(cell.getValue()).format('DD-MM-YYYY hh:mm A') || '-'}</div>
        } },
    ], []);

    const [data, setDate] = useState([]);
    const [isLoading, setLoading] = useState();

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        const userData = localdata.GET_DATA();
        setLoading(true);
        axios
            .get(Services.GET_ALL_AUDIT(0, 1000), {
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                },
            })
            .then((res) => {
                console.log(res.data);
                if (res.data.results.length > 0) {
                    setDate(res.data.results);
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    return (
        <div className="w-100 p-4">
            <Page title="User">
                <div className='d-flex justify-content-between pb-4'>
                    <h4> Change History</h4>
                </div>
                <MaterialReactTable
                    columns={columns}
                    isLoading={isLoading}
                    data={data}
                    enableColumnOrdering
                />
                
            </Page>

        </div>
    )
}

export default UserList;