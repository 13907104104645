/* eslint-disable */

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
// material
import { Box, IconButton, Card, Link, Typography, Stack, Button, CardHeader, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ImgsViewer from "react-images-viewer";

import { localdata } from '../../../service/localdata';

// components
import Label from '../../../components/Label';
import { ColorPreview } from '../../../components/color-utils';
import Iconify from '../../../components/Iconify';
import Services from '../../../service/Services';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

PostCard.propTypes = {
  product: PropTypes.object,
};

export default function PostCard({ product, RefreshPostData, isOther }) {
  const { title, content, tags, id, picture, createdAt, user, type, url, colorCode, isDeleted, contentURL, message } = product;
  const [open, setOpen] = useState(false);
  console.log(product)
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isOpenImage, setOpenImage] = useState(false);

  const DeletePost = (deleteId) => {
    const userData = localdata.GET_DATA();
    const service = isOther ? Services.DELETE_ANNOUNCEMENT(deleteId) : Services.DELETE_POST(deleteId);

    axios
      .delete(service, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        console.log(res);
        RefreshPostData();
        setOpen(false);

        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };
  return (
    <>
      {success && (
        <Alert variant="filled" severity="success" style={{ position: 'absolute', top: 100, right: 10, zIndex: 999 }}>
          <AlertTitle>Success</AlertTitle>
          Post deleted successfully
        </Alert>
      )}

      {error && (
        <Alert variant="filled" severity="error" style={{ position: 'absolute', top: 100, right: 10, zIndex: 999 }}>
          <AlertTitle>Error</AlertTitle>
          Something Wrong <strong>check it out!</strong>
        </Alert>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Post Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Post?
            <ul className='mt-3'>
              <li>This will permanently remove from database</li>
              <li>You won't recover this Data</li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ margin: 10 }}>
          <Button onClick={() => setOpen(false)} variant="outlined" style={{ marginRight: 10 }}>
            Cancel
          </Button>
          <Button onClick={() => DeletePost(id)} autoFocus variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe">
              {user.picture ? <img src={user.picture} onClick={() => setOpenImage(true)} height="100%" alt={user.name} /> : user.name[0]}
            </Avatar>
          }
          action={
            <IconButton aria-label="settings" onClick={() => setOpen(true)} color="error">
              <Iconify icon="eva:trash-2-fill" width={20} height={20} />

            </IconButton>
          }
          title={user.name}
          subheader={user.email}
        />
        <hr className='mb-0 p-0' />
        {!!user.picture && <ImgsViewer
          imgs={[
            { src: user?.picture, caption: user?.name }
          ]}
          className="pointer"
          currImg={0}
          isOpen={isOpenImage}
          onClose={() => {
            setOpenImage(false);
          }}
        />}
        {
          type === 'bible' && <>
            <div className='p-4' style={{ backgroundColor: colorCode }}>
              <div className='bible-content text-center'>
                <h5 className='text-white'>{title}</h5>
                <p className='text-start px-3 pt-3 text-white'>
                  <Typography dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, "<br />") }} variant="subtitle" />
                </p>
              </div>
            </div>
          </>
        }

        <Stack spacing={2} sx={{ p: 2 }}>

          {
            type !== 'bible' && <>
              {picture && <img alt="post-data" src={picture} style={{ height: 450, objectFit: 'contain', backgroundColor: '#001324' }} />}
              {url && type === 'image' && !picture && <img alt="post-data" src={url} style={{ height: 450, objectFit: 'contain', backgroundColor: '#001324' }} />}
              {url && type === 'video' &&
                <iframe title={title} width={'100%'} height="400" src={`https://www.youtube.com/embed/${url}?controls=1`} allowFullScreen />
              }
              <Stack direction="row" alignItems="center" sx={{ px: 2 }} justifyContent="space-between">
                <Typography variant="h5">{title}</Typography>
              </Stack>
              {isOther
                ? <Typography sx={{ px: 2 }} dangerouslySetInnerHTML={{ __html: message?.replace(/\n/g, "<br />") }} variant="subtitle" />
                : <Typography sx={{ px: 2 }} dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, "<br />") }} variant="subtitle" />
              }

              {contentURL && <p>{contentURL.split(',').map((e) => {
                return <a className='px-3 d-block' href={e}>{e}</a>
              })}</p>}
              {url && type === 'audio' &&
                <audio id="audio" controls>
                  <source src={url} />
                  Your browser does not support the audio element.
                </audio>
              }
            </>
          }
          <Stack direction="row" sx={{ px: 1 }}>
            <div className='w-100 d-flex justify-content-between'>
              <div className='d-flex '>
                <IconButton style={{ alignSelf: 'center' }} color="primary">
                  <Iconify icon="ic:round-access-time-filled" width={20} height={20} />
                </IconButton>
                <span className='mt-1 pt-1 text-secondary p'>{moment(createdAt).format('DD-MM-YYYY, hh:mm:A')}</span>
              </div>
              {isDeleted &&
                <div>
                  <span class="badge bg-danger">Deleted</span>
                </div>
              }
            </div>
          </Stack>
        </Stack>
      </Card>
    </>
  );
}
