// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Posts',
    path: '/dashboard/posts',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'Announcement',
    path: '/dashboard/announcement',
    icon: getIcon('eva:calendar-fill'),
  },
  {
    title: 'Files',
    path: '/dashboard/files',
    icon: getIcon('eva:folder-fill'),
  },
  {
    title: 'Modified Profiles',
    path: '/dashboard/update',
    icon: getIcon('material-symbols:change-circle'),
  },
  {
    title: 'Users',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Change History',
    path: '/dashboard/change-history',
    icon: getIcon('eva:pie-chart-fill'),
  },
  {
    title: 'Events',
    path: '/dashboard/event',
    icon: getIcon('eva:book-fill'),
  },
  {
    title: 'Settings',
    path: '/dashboard/settings',
    icon: getIcon('eva:settings-2-fill'),
  },
  {
    title: 'Notifications',
    path: '/dashboard/notification',
    icon: getIcon('eva:bell-fill'),
  },
  {
    title: 'logout',
    path: '/logout',
    icon: getIcon('ion:log-out'),
  },
];

export default navConfig;
