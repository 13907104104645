import { filter, transform } from 'lodash';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Backdrop,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';

// components
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import SearchNotFound from '../../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../sections/@dashboard/user';
// mock
import USERLIST from '../../../_mock/user';
import Services from '../../../service/Services';
import { localdata } from '../../../service/localdata';
import VersionModal from './model/VersionModal';
import SettingsMenu from './settings/Menu';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'version', label: 'Version', alignRight: false },
  { id: 'VersionCode', label: 'Version Code', alignRight: false },
  { id: 'OS', label: 'OS', alignRight: false },
  { id: 'UpdatedAt', label: 'Updated At', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function Version() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');
  const [selectedData, setSelectedData] = useState({});

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [addPopup, setAddPopup] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return paginate(rowsPerPage, page + 1, filter(
        array,
        (_user) =>
          _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          _user.mobile.toLowerCase().indexOf(query.toLowerCase()) !== -1
      ));
    }
    return paginate(rowsPerPage, page + 1, stabilizedThis.map((el) => el[0]));
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = usersFullData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilteredUsers(paginate(rowsPerPage, newPage + 1));
  };

  const paginate = (pageSize, pageNumber, array) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    const res = [...[...(array || usersFullData)].slice((pageNumber - 1) * pageSize, pageNumber * pageSize)];

    return res;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilteredUsers(paginate(parseInt(event.target.value, 10)), 1);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [usersFullData, setUsersFullData] = useState([]);
  // const isUserNotFound = filteredUsers.length === 0;

  const [updateLoading, setUpdateLoading] = useState(false);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [success2, setSuccess2] = useState(false);

  const onDelete = (deleteId) => {
    const userData = localdata.GET_DATA();
    axios
      .delete(Services.VERSION(deleteId), {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        console.log(res);
        RefreshData();

        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  // applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
  const [isLoading, setLoading] = useState(true);
  const RefreshData = () => {
    const userData = localdata.GET_DATA();
    setLoading(true);
    axios
      .get(Services.VERSION(), {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        console.log(res.data.versions);
        setFilteredUsers(paginate(rowsPerPage, page + 1, res.data.versions));
        setUsersFullData(res.data.versions);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    RefreshData();
  }, []);

  const NewfilteredUsers = applySortFilter(usersFullData, getComparator(order, orderBy), filterName);

  return (
    <Page title="Verisons">
      <VersionModal open={addPopup} onClose={() => {
        setAddPopup(false)
        RefreshData();
      }} data={selectedData} />
      {success && (
        <Alert variant="filled" severity="success" style={{ position: 'absolute', top: 100, right: 10, zIndex: 999 }}>
          <AlertTitle>Success</AlertTitle>
          Account deleted successfully
        </Alert>
      )}

      {error && (
        <Alert variant="filled" severity="error" style={{ position: 'absolute', top: 100, right: 10, zIndex: 999 }}>
          <AlertTitle>Error</AlertTitle>
          Something Wrong <strong>check it out!</strong>
        </Alert>
      )}

      {success2 && (
        <Alert variant="filled" severity="success" style={{ position: 'absolute', top: 100, right: 10, zIndex: 999 }}>
          <AlertTitle>Success</AlertTitle>
          Version details updated successfully
        </Alert>
      )}

      <Container className='p-0 px-3'>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Version
          </Typography>
          <Button variant="contained" onClick={() => {
            setSelectedData({});
            setTimeout(() => {
              setAddPopup(true)
            });
          }} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Version
          </Button>
        </Stack>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999999 }}
          open={updateLoading}
          onClick={() => setUpdateLoading(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {isLoading ? (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress
              style={{
                position: 'absolute',
                top: '50%',
                left: '55%',
                translate: transform('-50%', '-50%'),
                zIndex: 9,
              }}
            />
          </Box>
        ) : (
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={usersFullData.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {NewfilteredUsers.length > 0
                      ? NewfilteredUsers.map((row) => {
                        const { id, version, versionCode, updatedAt, os } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={10} style={{ marginLeft: 20 }}>
                                <Typography variant="subtitle2" noWrap style={{ marginLeft: 10 }}>
                                  {version}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{versionCode}</TableCell>
                            <TableCell align="left">{os}</TableCell>
                            <TableCell align="left">{moment(updatedAt).format('DD-MM-YYYY hh:mm A')}</TableCell>

                            <TableCell align="right">
                              <SettingsMenu
                                id={id}
                                onDelete={onDelete}
                                onEdit={(data) => {
                                  setAddPopup(true);
                                  setSelectedData({ ...data });
                                }}
                                selectedData={row}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                      : null}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {NewfilteredUsers.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={usersFullData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>
    </Page>
  );
}
