import Ipconfig from './IpConfig';

const Services = {
  // login page
  LOGIN: () => {
    return `${Ipconfig.BACKEND_IP()}/login`;
  },
  UPDATE_USER: (userid) => {
    return `${Ipconfig.BACKEND_IP()}/users/update/${userid}`;
  },
  DELETE_USER: (userid) => {
    return `${Ipconfig.BACKEND_IP()}/users/${userid}`;
  },
  BLOCK_UNBLOCK_USER: (userid) => {
    return `${Ipconfig.BACKEND_IP()}/users/blockUnblock/${userid}`;
  },
  GET_ALL_UPDATE_REQUESTS: () => {
    return `${Ipconfig.BACKEND_IP()}/review`;
  },
  GET_DIFF_UPDATE: (userid) => {
    return `${Ipconfig.BACKEND_IP()}/review/${userid}`;
  },
  // post
  ADD_POST: () => {
    return `${Ipconfig.BACKEND_IP()}/post`;
  },
  SEARCH_POST: () => {
    return `${Ipconfig.BACKEND_IP()}/post/search`;
  },
  DELETE_POST: (postid) => {
    return `${Ipconfig.BACKEND_IP()}/post/delete/${postid}`;
  },
  // post
  ADD_ANNOUNCEMENT: () => {
    return `${Ipconfig.BACKEND_IP()}/announcements`;
  },
  SEARCH_ANNOUNCEMENT: () => {
    return `${Ipconfig.BACKEND_IP()}/announcements/search`;
  },
  DELETE_ANNOUNCEMENT: (postid) => {
    return `${Ipconfig.BACKEND_IP()}/announcements/${postid}`;
  },
  GET_ALL_USERS: (page, size) => {
    return `${Ipconfig.BACKEND_IP()}/users?page=${page}&size=${size}`;
  },
  BULK_UPDATE: () => {
    return `${Ipconfig.BACKEND_IP()}/users/bulkUpdate`;
  },
  GET_ALL_POSTS: (page, size) => {
    return `${Ipconfig.BACKEND_IP()}/post/admin?page=${page}&size=${size}`;
  },
  GET_ALL_ANNOUNCEMENT: (page, size) => {
    return `${Ipconfig.BACKEND_IP()}/announcements?page=${page}&size=${size}`;
  },
  GET_ALL_COUNTS: () => {
    return `${Ipconfig.BACKEND_IP()}/dashboard`;
  },
  GET_STATISTICS: () => {
    return `${Ipconfig.BACKEND_IP()}/dashboard/statistic`;
  },

  APPROVE_REQUEST: (UserId) => {
    return `${Ipconfig.BACKEND_IP()}/review/approve/${UserId}`;
  },
  REJECT_REQUEST: (UserId) => {
    return `${Ipconfig.BACKEND_IP()}/review/reject/${UserId}`;
  },
  FILES: (id) => {
    if (id) {
      return `${Ipconfig.BACKEND_IP()}/files/${id}`;

    }
    return `${Ipconfig.BACKEND_IP()}/files`;
  },
  VERSION: (id) => {
    if (id) {
      return `${Ipconfig.BACKEND_IP()}/version/${id}`;
    }
    return `${Ipconfig.BACKEND_IP()}/version`;
  },
  GROUP: (id, page, size) => {
    if (id) {
      return `${Ipconfig.BACKEND_IP()}/groups/${id}`;
    }
    return `${Ipconfig.BACKEND_IP()}/groups?page=${page}&size=${size}`;
  },
  QUESTION: (id, page, size) => {
    if (id) {
      return `${Ipconfig.BACKEND_IP()}/questions/${id}`;

    }
    return `${Ipconfig.BACKEND_IP()}/questions?page=${page}&size=${size}`;
  },
  COUNTRY: () => {
    return `${Ipconfig.BACKEND_IP()}/country`;
  },
  STATE: (name) => {
    return `${Ipconfig.BACKEND_IP()}/state/${name}`;
  },
  CITY: (name) => {
    return `${Ipconfig.BACKEND_IP()}/city/${name}`;
  },
  EVENTS: (id) => {
    if (id) {
      return `${Ipconfig.BACKEND_IP()}/event/${id}`;
    }
    return `${Ipconfig.BACKEND_IP()}/event`;
  },
  EVENT_FORM: (id) => {
    if (id) {
      return `${Ipconfig.BACKEND_IP()}/eventForm/${id}`;
    }
    return `${Ipconfig.BACKEND_IP()}/eventForm`;
  },
  COLOR: (id, page, size) => {
    if (id) {
      return `${Ipconfig.BACKEND_IP()}/colors/${id}`;

    }
    return `${Ipconfig.BACKEND_IP()}/colors?page=${page}&size=${size}`;
  },
  NOTIFICATION: (id, page, size, type) => {
    if (id) {
      return `${Ipconfig.BACKEND_IP()}/notifications/${id}?page=${page}&size=${size}&type=${type}`;

    }
    return `${Ipconfig.BACKEND_IP()}/notifications?page=${page}&size=${size}&type=${type}`;
  },
  GET_ALL_AUDIT: (page, size) => {
    return `${Ipconfig.BACKEND_IP()}/audit?page=${page}&size=${size}`;
  },
};

export default Services;
